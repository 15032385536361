.TableContainer{
    display: flex;
    flex-flow: column;
    width: 100%;
    padding: 5px;
}

.TableContainer tbody tr:focus{
    background-color: var(--primary-bg-color) !important;
    color: white;
    outline: none;
}

.TableContainer tbody tr:hover{
    background-color:#555     !important;
    color: white;
}

.CustomTable{
    width: 100%;
    height: 90%;
    overflow: auto;
}

.ToolContainer{
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    align-items: center;
    gap: 2%;
}

.PaginationContainer{
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
}

.FilterContainer{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
    width: 100%;
}

.FilterContainer input{
    width: 100%;
}

.PageBtn{
    margin: 2px;
    padding: 0px 10px;
}

@media (min-width:1000px){
    .PaginationContainer{
        flex-flow: row;
    }
    .FilterContainer{
        width: 30%;
    }

    .ToolContainer{
        flex-flow: row;
    }
}