.LoginForm{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 15% auto;
    padding: 20px;
    background-color: white;
    color: #003453;
}

.ShadowBox{
    border-radius: 15px;
    box-shadow:  0px 2px 10px black;
}

@media (min-width:993px){
    .LoginForm{
        width: 50%;
    }
}