.Spinner,
.Spinner:before,
.Spinner:after {
  background:var(--primary-bg-color);
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.Spinner {
  color: var(--primary-bg-color);
  text-indent: -9999em;
  margin: 88px auto;
  position: fixed;
  top: 30%;
  left: 45%;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  z-index: 99999;
}
.Spinner:before,
.Spinner:after {
  position: absolute;
  top: 0;
  content: '';
}
.Spinner:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.Spinner:after {
  left: 1.5em;
}

.Backdrop{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99998;
  background-color: rgba(255,255,255,0.5);
}

@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
