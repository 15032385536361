:root{
  --primary-bg-color:#282c34;
  --secondary-bg-color: #fff;
}

.App {
  text-align: center;
  background-color: #fff;
}

::-webkit-scrollbar-track
{
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #fff;
  border-radius: 10px;
}

::-webkit-scrollbar
{
    height: 10px;
    width: 6px;
    background-color: #fff;
}

::-webkit-scrollbar-thumb
{
    background-color:#000;
    border-radius: 10px;
}

.AppHeader{
  background-color:var(--primary-bg-color);
  height: 100px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  font-size: calc(15px + 2vmin);
  color: var(--secondary-bg-color);
  box-shadow: 0px 3px 4px #555;
  padding: 8px;
}

.AppUser{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  padding: 4px;
  height: 100%;
  width: 100%;
}

.btn.LogoutBtn{
  background: white !important;
  color: var(--primary-bg-color);
  text-transform: none;
  font-size: 16px;
}

.AppMain{
  min-height: 85vh;
  display: flex;
}

.AppFooter{
  background-color:var(--primary-bg-color);
  height: 100px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: var(--secondary-bg-color);
  padding: 8px;
}

.AppFooter a:link,.AppFooter a:visited,.AppFooter a:active{
  color: var(--secondary-bg-color);
}

@media (min-width: 768px){
  .AppHeader,.AppFooter{
    flex-flow: row;
  }
  .AppUser{
    flex-flow: column;
    justify-content: space-around;
    width: fit-content;
  }
}

