.Dashboard{
    display: flex;
    justify-content: center;
    padding: 8px;
    min-height: inherit;
    width: 100%;
}

.ActionBar {
    position: absolute;
    left: 0;
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.Dashboard > .TableContainer{
    margin-top: 50px;
}

.FormContainer {
    position: fixed;
    background: white;
    width: 100%;
    z-index: 9999;
    padding: 8px;
    box-shadow: 0px 0px 5px;
    top: -150vh;
    transition: all 0.4s;
}

.FormContainer.FormOpen{
    top: 15px;
}

.FormHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2rem;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.AccountForm > .row{
    height: 75vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.ActionDiv{
    display: flex;
    justify-content: space-around;
    margin: 4px;
}

.ActionBtn{
    text-transform: none;
}

#delete_modal{
    border-radius: 10px;
    overflow: hidden;
}

#delete_modal .modal-footer{
    display: flex;
    justify-content: space-evenly;
}

#delete_modal .btn{
    margin: 0px;
    box-shadow: -2px 2px 3px black;
}

@media (min-width: 768px){
    .FormContainer{
        width: 50%;
    }
    #delete_modal{
        font-size: 1.5rem;
        width: 40%;
    }
}

@media (max-height: 600px){
    .AccountForm > .row{
        height: 75vh;
    }
}